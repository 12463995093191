import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { ONE_SECOND } from '@consts/DateConsts'

import { Spacings } from '@contracts/enums/Spacings'
import { BorderRadiuses } from '@contracts/types/BorderRadixes'
import { Session } from '@contracts/types/Session'

import { Colors } from '@pure/assets/Colors'
import Images from '@pure/assets/PayImages'
import Texts from '@pure/assets/PayTexts'
import { DayJS } from '@pure/libs/DayJsHelper'
import { getLastFreeSessionEvent } from '@pure/libs/SessionHelper'

import { useSite } from '@pay/hooks/useSite'
import { getUntilText } from '@pay/libs/getUntilText'

import { Container } from './ActiveSessionWidget'
import { ActiveSessionWidgetRowPrice } from './ActiveSessionWidgetRow'
import Box from './Box'
import FigmaImage from './FigmaImage'
import FigmaText from './FigmaText'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { StationInfoDrawer } from './StationInfoDrawer'

const now = dayjs()

type Props = {
  session: Session
  headerText?: string
  hideTime?: boolean
}

export const ActiveSessionWidgetFree: React.FC<Props> = ({ session, headerText, hideTime }) => {
  const parkId = parseInt(session.parkingSession?.siteId || '')
  const { data: site } = useSite(parkId.toString())

  const lastFreeSessionEndedAt = getLastFreeSessionEvent(session)?.endedAt
  const finishDate = dayjs(lastFreeSessionEndedAt)

  const [showParkDetails, setShowParkDetails] = useState(false)
  const [ellapsed, setEllapsed] = useState(0)

  const untilText = getUntilText(finishDate.format())

  const seconds = finishDate.add(-ellapsed, 'milliseconds').diff(now, 'second')
  const formattedTime = DayJS.duration(seconds, 'seconds').format('HH:mm:ss')

  useEffect(() => {
    if (dayjs(finishDate).isBefore(now)) return
    setTimeout(() => setEllapsed(ellapsed + ONE_SECOND), ONE_SECOND)
  }, [ellapsed])

  return (
    <Container fullWidth fullPadding spacing={Spacings.s}>
      <StationInfoDrawer open={showParkDetails} onClose={() => setShowParkDetails(false)} site={site} />
      <Box fullWidth direction="row" align="center" justify="space-between" bottom spacing={Spacings.s}>
        <Box direction="row" align="center">
          <FigmaImage imageKey={Images.activeSessionPark} />
          <Box left spacing={Spacings.s}>
            <FigmaText textKey={Texts.textActiveSessionActiveSessionActiveSessionTimeParking} text={headerText} />
          </Box>
        </Box>
        <Box onClick={() => setShowParkDetails(true)} pointer>
          <FigmaImage imageKey={Images.infoIconPurple} />
        </Box>
      </Box>
      <Box fullWidth>
        <ActiveSessionWidgetRowPrice
          textKey={Texts.textActiveSessionActiveSessionActiveSessionTimeFreeParking}
          icon={Images.timeIcon}
          rightValue={hideTime ? undefined : formattedTime}
          rightValueSuffixElement={
            !hideTime && lastFreeSessionEndedAt ? (
              <FigmaTextWithStyle type="captionItallic" text={untilText} />
            ) : undefined
          }
        />
      </Box>
    </Container>
  )
}

export default ActiveSessionWidgetFree

export const styles = {
  wrapper: {
    borderRadius: BorderRadiuses.lightlyRounded,
    borderWidth: 2,
    width: '100%',
    padding: Spacings.s,
    borderColor: Colors.brandPink,
    backgroundColor: Colors.white
  },
  title: {
    paddingLeft: Spacings.xs
  },
  totalTimer: {
    fontSize: 22,
    color: Colors.brandDarkBlue,
    fontWeight: 'bold'
  }
}
