import invariant from 'invariant'

import { Session } from '@contracts/types/Session'

import { getTotalParkingPriceForSession } from '@pure/libs/PaymentHelper'
import { isFreeSession } from '@pure/libs/SessionHelper'

import { db } from './FirebaseOptions'
import { HomeMachineState, HomeSteps } from './HomeHelper'
import { Services } from './HomeMachineHelper'

export const onPressContinue = (homeMachineState: HomeMachineState, s: Services): Promise<HomeMachineState> =>
  Promise.resolve().then(() => {
    switch (homeMachineState.step) {
      case HomeSteps.LANDING:
        return { ...homeMachineState, step: HomeSteps.ENTER_REG_NO }
      case HomeSteps.ENTER_REG_NO: {
        const { regNo } = homeMachineState.data

        return s
          .getActiveSessionForPlate({ plate: regNo || '' })
          .then((session: Session | undefined): HomeMachineState | Promise<HomeMachineState> => {
            // This is really good for debugging prod
            //Log.info(`onPressContinue > getActiveSessionForPlate > session ${JSON.stringify(session)}`)

            switch (true) {
              // case session?.is_digital_code_active:
              case !isFreeSession(session) && getTotalParkingPriceForSession(session) === 0:
              case !session:
                return {
                  ...homeMachineState,
                  data: { ...homeMachineState.data, session: null },
                  step: HomeSteps.ENTER_REG_NO
                }
            }

            return Promise.resolve()
              .then(() => {
                const site = homeMachineState.data.site
                const siteId = session?.parkingSession?.siteId
                invariant(siteId, '!parkId')
                if (!site) return s.getSite(siteId, db)
                return site
              })
              .then((site) => ({
                ...homeMachineState,
                data: { ...homeMachineState.data, session, site: site },
                step: HomeSteps.UNPAYED_SESSIONS
              }))
          })
      }
      case HomeSteps.UNPAYED_SESSIONS: {
        const { session, site: park } = homeMachineState.data
        invariant(session, '!session')
        invariant(park, '!park')

        return s.onClickPayWithStripe(homeMachineState).then((url) => {
          s.redirect(url)
          return homeMachineState
        })
      }
      default:
        throw new Error(`Unknown step: ${homeMachineState.step}`)
    }
  })
