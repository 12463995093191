import invariant from 'invariant'

import { GUEST_FREE } from '@consts/Consts'

import { PlaceCode, PlaceType, SiteCameraEvent } from '@contracts/types/CameraEvent'
import { HikerUser } from '@contracts/types/HikerUser'
import { ParkingSessionEvent, ParkingSessionEventOffense, ParkingSessionType } from '@contracts/types/ParkingSession'
import { ChildSession, OnSessionClosedRequest, Session, SessionEventName, SessionOld } from '@contracts/types/Session'

import { DayJS } from './DayJsHelper'
import { getPartialPayment } from './PaymentHelper'

export const toNew = (session: SessionOld | Session): Session => {
  if ((session as Session).parkingSession) return session as Session

  if (!(session as SessionOld).backendSession) return session
  return fromOldToNew(session as SessionOld)
}

const fromOldToNew = (session: SessionOld): Session => {
  const { backendSession } = session
  const startedAt = backendSession.park_session?.started_at?.toString()
  const lastSessionClosedAt = DayJS(startedAt).add(-1, 'second').format()
  const endedAt = backendSession.park_session?.ended_at?.toString() || null
  const plate = backendSession.park_session?.plate || ''

  const siteId = backendSession.location?.park_id?.toString()
  const userId = backendSession.user?.user_id?.toString()

  invariant(startedAt, `Session must have a started_at, got ${startedAt} for sessionId ${session.id}`)
  invariant(siteId, `Session must have a siteId, got ${siteId} for sessionId ${session.id}`)

  const user = session.backendSession?.user

  const type = getTypeForLBESession(session)

  let events: ParkingSessionEvent[] = session.request ? getEventsFromOnSessionClosedRequest(session.request) : []
  if (events.length === 0 && backendSession.park_session?.session_status === 'fri')
    events = [
      {
        name: SessionEventName.PARKING_FREE,
        startedAt,
        endedAt: DayJS(backendSession.park_session.countdown?.finish_date).format()
      }
    ]

  return {
    ...session,
    parkingSession: {
      id: session.id,
      type,
      startedAt,
      plate,
      events,
      gateEvents: {},
      slotEvents: {
        [endedAt || startedAt]: { place_code: backendSession.park_session?.slot } as SiteCameraEvent
      },
      siteId,
      currentPayment: getPartialPayment(session),
      lastCameraEvent: {
        siteId,
        place_type: PlaceType.Gate,
        place_code: PlaceCode.Gate,
        plate_txt: plate
      } as SiteCameraEvent,
      endedAt,
      lastSessionClosedAt,
      sessionLogic: undefined,
      userId,
      user: user as HikerUser
    }
  }
}

export const isFalseSession = (session: Session): boolean =>
  session?.parkingSession?.events?.some((e) => e.name === SessionEventName.PARKING_FALSE_SESSION) || false

function getEventsFromOnSessionClosedRequest(request: OnSessionClosedRequest): ParkingSessionEvent[] {
  return (
    request.childSessions?.map((c) => ({
      name: c.offense_type ? SessionEventName.PARKING_OFFENSE : SessionEventName.PARKING_NORMAL,
      type: getSessionEventTypeFromOldSession(c),
      offenseType: c.offense_type,
      startedAt: c.started_at,
      endedAt: c.ended_at,
      slotPlaceCode: c.slot_label,
      pricingItems: [
        {
          startedAt: c.started_at,
          endedAt: c.ended_at,
          cost: c.costs,
          item: {} as any
        }
      ]
    })) || []
  )
}

function getSessionEventTypeFromOldSession(c: ChildSession): ParkingSessionEvent['type'] {
  if (c.offense_type) return 'offense'
  if (c.free_type) return 'free'
  return 'parking'
}

function getTypeForLBESession({ backendSession }: SessionOld): ParkingSessionType | undefined {
  if (backendSession.park_session?.session_status === GUEST_FREE) return ParkingSessionType.GUEST
  return undefined
}

export function isFreeSession(session?: Session, now: string = DayJS().format()) {
  const lastSessionEvent = getLastFreeSessionEvent(session)
  return (
    !!lastSessionEvent &&
    (!lastSessionEvent?.endedAt || (lastSessionEvent?.endedAt && DayJS(now).isBefore(lastSessionEvent?.endedAt)))
  )
}

export function getLastFreeSessionEvent(session?: Session) {
  const events = session?.parkingSession?.events || []
  const lastEvent = events[events.length - 1]
  return lastEvent?.type === 'free' ? lastEvent : undefined
}

export const getOffenseEventsForSession = (s: Session): ParkingSessionEventOffense[] =>
  (s.parkingSession?.events?.filter((e) => e.type === 'offense') || []) as ParkingSessionEventOffense[]
